










































































import { Vue, Component } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsVerifyNipKuspitViewModel
  from '@/vue-app/view-models/components/contract-savings/verify-nip/contract-savings-verify-nip-kuspit-view-model';
import Avatar from '@/vue-app/components/contract-savings/Avatar.vue';

@Component({
  name: 'ContractSavingsVerifyNipKuspit',
  components: {
    Avatar,
  },
})
export default class ContractSavingsVerifyNipKuspit extends Vue {
  verify_nip_kuspit_view_model = Vue.observable(
    new ContractSavingsVerifyNipKuspitViewModel(),
  );

  prevStep() {
    this.$emit('prevStep');
  }

  nextStep() {
    this.$emit('nextStep');
  }

  mounted() {
    this.verify_nip_kuspit_view_model.small_screen = getScreenSizeVariant() === 'small';
  }
}

