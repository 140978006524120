import TYPES from '@/types';

// Application
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';

// Domain
import Inject from '@/modules/shared/domain/di/inject';

export default class AvatarViewModel {
  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  readonly getPersonQuery!: GetPersonQueryService;

  avatar_url = null;

  my_initials = '';

  get initials() {
    const person = this.getPersonQuery.execute();
    this.my_initials = `${person.name.charAt(0)}${person.last_name.charAt(0)}`;
    return this.my_initials;
  }
}
