import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { requiredRule, RuleResponseType } from '@/vue-app/utils/form-rules';

// Application
import OnBoardingSearchEmailsQuery
  from '@/modules/on-boarding/emails/application/queries/on-boarding-search-emails-query';

// Domain
import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsStartKuspitViewModel {
  @Inject(TYPES.ON_BOARDING_SEARCH_EMAILS_QUERY)
  readonly onBoardingSearchEmailsQuery!: OnBoardingSearchEmailsQuery;

  i18n_namespace = 'components.onboarding-kuspit.verify-nip';

  nip = '';

  nip_rules = [
    requiredRule,
    (value: string): RuleResponseType => value.length === 7 || i18n.t('utils.form-rules.nip_format')
      .toString(),
  ];

  loading = false;

  form_validity = false;

  small_screen: null | boolean = null;

  get email() {
    return this.onBoardingSearchEmailsQuery.execute()
      .find(
        (email) => email.customer_email_default,
      ) || { email: { email_address: 'con el que ingresaste' } };
  }

  get canContinue(): boolean {
    return this.form_validity;
  }
}
