


















import { Component, Vue, Prop } from 'vue-property-decorator';
import AvatarViewModel from '@/vue-app/view-models/components/contract-savings/avatar-view-model';

@Component({ name: 'Avatar' })
export default class Avatar extends Vue {
  @Prop({ default: '7rem' }) avatar_size!: string;

  avatar_view_model = Vue.observable(new AvatarViewModel());
}
